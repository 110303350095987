<template>
  <Teleport to="body">
    <Transition
      name="fade-translate"
      @after-enter="modal.open"
      @after-leave="modal.close"
    >
      <div
        v-if="active"
        ref="popup"
        class="popup grid"
        aria-modal="true"
        role="dialog"
      >
        <div class="content">
          <header class="header">
            <slot name="header" />
            <button class="close" :aria-label="t('close')" @click="close">
              <CloseIcon class="close-icon" aria-hidden="true" role="img" />
            </button>
          </header>
          <slot />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'

import CloseIcon from '@/assets/icons/close.svg?component'

defineProps<{
  active: boolean
}>()

const { t } = useI18n()
const emit = defineEmits(['close'])

function close() {
  emit('close')
}

const popup = ref<HTMLElement>()
const modal = useModal(popup)

onKeyStroke('Escape', close)
</script>

<style lang="scss" scoped>
.popup {
  @include fill-space($position: fixed);
  inset: 0;
  z-index: z(popup);
  height: 100%;
  padding-top: map-get($space-around, xs);
  overflow-y: auto;
  background-color: $light-grey;

  @include media-up(md) {
    padding-top: map-get($space-around, md);
  }

  @include media-up(lg) {
    padding-top: map-get($space-around, lg);
  }

  @include media-up(xxl) {
    padding-top: map-get($space-around, xxl);
  }

  .fixed-header-visible & {
    height: calc(100% - $nav-height);
    padding-top: 0;
    margin-top: $nav-height;
  }
}

.content {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
}

.close {
  @include focus-visible;
}

.close-icon {
  @include size(rem(24px));
  display: block;
  color: $black;

  @include media-up(md) {
    @include size(rem(48px));
  }

  @include media-up(lg) {
    @include size(rem(64px));
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: rem(10px);
  margin-bottom: rem(6px);
  border-bottom: $base-border;

  @include media-up(md) {
    padding-bottom: rem(13px);
  }

  @include media-up(lg) {
    margin-bottom: rem(24px);
  }

  .fixed-header-visible & {
    padding-top: rem(10px);

    @include media-up(md) {
      padding-top: rem(13px);
    }
  }
}
</style>
